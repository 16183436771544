<template>
  <vx-card class="cash-box-menu-page top-zero-radius"
           :class="{'main-box md:w-1/2': cashBoxId === 0, 'in-modal': cashBoxId > 0}"
           v-if="cashBox && Object.keys(cashBox).length > 0">

    <profile-main-box :profile="getCashBoxProfile"
                      :actions-list="actionsList"
                      @transactionsTreasuryCashBox="showCashBoxTransactionsPromptStatus = true"/>

    <!--  show cashBox turnover prompt  -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCashBoxTransactionsPromptStatus"
      @close="showCashBoxTransactionsPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">

          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.cashBoxes.transactions.dynamicTitle', {cashBox: cashBox.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
<!--            <div class="mx-2" @click="handleClick('printCashBoxTransactions')">-->
<!--              <custom-icon icon="PRINT"/>-->
<!--            </div>-->
            <div @click="showCashBoxTransactionsPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <cash-box-transactions :cash-box-id="cashBoxId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </vx-card>
</template>

<script>
import ProfileMainBox from '@/components/profileMainBox/profileMainBox'
import {getCashBox} from '@/http/requests/cashBoxes'
import CustomIcon from "../../../../../../components/customIcon/customIcon";
import CashBoxTransactions from "../transactions/cashBoxTransactions";

export default {
  name: 'cashBoxMenu',
  components: {CashBoxTransactions, CustomIcon, ProfileMainBox},
  metaInfo () {
    return {
      title: this.cashBox.hasOwnProperty('name') ? this.$t('treasury.cashBoxes.cashBox.dynamicTitle', {cashBox: this.cashBox.name || ''}) : this.$t('treasury.cashBoxes.cashBox.title')
    }
  },
  props: {
    cashBoxId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      showCashBoxTransactionsPromptStatus: false,
      cashBox: {},
      actionsList: [
        {
          event: 'transactionsTreasuryCashBox',
          i18n: 'treasury.cashBoxes.cashBox.actions.transactions',
          icon: 'icon-layers',
          iconPack: 'feather'
        }
      ],
      actions: {
        toolbar: [
          {
            id: {name: 'editTreasuryCashBox'},
            type: 'link',
            icon: 'icon-edit-2',
            iconPack: 'feather',
            color: 'warning'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'treasuryCashBoxes'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      }
    }
  },
  computed: {
    getCashBoxProfile () {
      const cashBox = {
        avatar: this.cashBox.avatar ? this.cashBox.avatar : 'https://www.chinadaily.com.cn/hkedition/attachement/jpg/site1/20141031/00221917e13e15bd167801.jpg',
        name: this.cashBox.name,
        details: [],
        importantData: [
          {
            key: this.$t('treasury.cashBoxes.cashBox.importantData.balance'),
            value: this.cashBox.balance ? Math.abs(this.cashBox.balance) : 0,
            class: this.cashBox.balance < 0 ? 'text-danger' : 'text-success',
            type: 'price'
          },
          {
            key: this.$t('treasury.cashBoxes.cashBox.importantData.openingBalance'),
            value: this.cashBox.opening_inventory ? this.cashBox.opening_inventory : 0,
            class: 'text-success',
            type: 'price'
          },
          {
            key: this.$t('treasury.cashBoxes.cashBox.importantData.transactionsCount'),
            value: this.cashBox.transaction_count || 0,
            class: 'text-primary'
          }
        ]
      }

      return cashBox
    }
  },
  created () {
    if (this.cashBoxId === 0) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
    }

    this.getCashBox()
  },
  methods: {
    getCashBox () {
      getCashBox(this.cashBoxId || this.$route.params.id).then(response => {
        this.cashBox = response.data.data
      })
    },

    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.cash-box-menu-page {

  &.in-modal {
    box-shadow: none;

    .vx-card__body {
      padding: 0.5rem;
    }
  }

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
